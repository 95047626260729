<template>
    <div>
        <app-layout>
            <template v-slot:header>
                <Header :title="$t('my_uploaded_documents')"
                        :isNewButton="checkPermission('uploadedstudentdocument_store')"
                        :is-filter="false"
                        @new-button-click="createFormShow">
                </Header>
            </template>
            <template v-slot:header-mobile>
                <HeaderMobile :title="$t('my_uploaded_documents')"
                              :isNewButton="checkPermission('uploadedstudentdocument_store')"
                              :is-filter="false"
                              @new-button-click="createFormShow">
                </HeaderMobile>
            </template>
            <datatable ref="datatable"
                       :isLoading.sync="datatable.isLoading"
                       :columns="datatable.columns"
                       :rows="datatable.rows"
                       :total="datatable.total"
                       :queryParams="datatable.queryParams"
                       :lineNumbers="false"
                       v-show="datatable.showTable"
                       :data-function="callService">
            </datatable>
            <CommonModal id="modal" size="lg">
                <template v-slot:CommonModalTitle>
                    <div>{{ $t('upload') | toUpperCase }}</div>
                </template>
                <template v-slot:CommonModalContent>
                    <ValidationObserver ref="form">
                        <div class="row" v-if="form">
                            <div class="col-12">
                                <ValidationProvider name="academic_year" rules="required"
                                                    v-slot="{valid, errors}">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('academic_year') }}</label>
                                        <academic-years-selectbox
                                            :validate-error="errors[0]"
                                            v-model="form.academic_year"></academic-years-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <ValidationProvider name="type" rules="required"
                                                    v-slot="{valid, errors}">
                                    <b-form-group>
                                        <label class="col-form-label pt-0">{{ $t('type') }}</label>
                                        <parameter-selectbox
                                            code="uploaded_student_document_types"
                                            :validate-error="errors[0]"
                                            v-model="form.type"></parameter-selectbox>
                                    </b-form-group>
                                </ValidationProvider>
                            </div>

                            <div class="col-12">
                                <ValidationProvider name="file" rules="required"
                                                    v-slot="{valid, errors}">
                                    <div class="d-flex custom-file-upload">
                                        <b-form-file v-model="form.file"
                                                     :state="errors[0] ? false : (valid ? true : null)"
                                                     :placeholder="$t('select_file')"
                                                     accept="image/jpeg, image/png, image/gif, image/jpg, application/pdf, .doc, .docx, application/msword"
                                                     :drop-placeholder="$t('drop_file')" ref="fileInput"
                                                     multiple></b-form-file>
                                        <b-button variant="outline-secondary"
                                                  @click="$refs.fileInput.$el.childNodes[0].click();">
                                            {{ $t('browse') }}
                                        </b-button>
                                    </div>
                                </ValidationProvider>
                            </div>


                            <div class="col-12 mt-3">
                                <b-button type="submit" @click="save" variant="primary">{{
                                        $t('save')
                                    }}
                                </b-button>
                            </div>

                        </div>
                    </ValidationObserver>
                </template>
            </CommonModal>

        </app-layout>
    </div>
</template>
<script>
import AppLayout from "@/layouts/AppLayout";
import Header from "@/layouts/AppLayout/Header"
import HeaderMobile from "@/layouts/AppLayout/HeaderMobile"
import DatatableFilter from "@/components/datatable/DatatableFilter";
import Datatable from "@/components/datatable/Datatable";
import qs from "qs";
import UploadedStudentDocument from "@/services/UploadedStudentDocument";
import ParameterSelectbox from "@/components/interactive-fields/ParameterSelectbox";
import CommonModal from "@/components/elements/CommonModal";
import PaymentPeriodsSelectbox from "@/components/interactive-fields/PaymentPeriodsSelectbox";
import MultiSelectbox from "@/components/interactive-fields/MultiSelectbox";
import CurrencySelectbox from "@/components/interactive-fields/CurrencySelectbox";
import PaymentTypesSelectbox from "@/components/interactive-fields/PaymentTypesSelectbox";
import {ValidationObserver, ValidationProvider} from 'vee-validate';
import BankSelectbox from "@/components/interactive-fields/BankSelectbox";
import YearsSelectbox from "@/components/interactive-fields/YearsSelectbox.vue";
import PaymentYearSelectbox from "@/components/interactive-fields/PaymentYearSelectbox.vue";

export default {
    components: {
        PaymentYearSelectbox,
        YearsSelectbox,
        BankSelectbox,
        PaymentTypesSelectbox,
        CurrencySelectbox,
        MultiSelectbox,
        PaymentPeriodsSelectbox,
        AppLayout,
        Header,
        HeaderMobile,
        DatatableFilter,
        Datatable,
        ParameterSelectbox,
        CommonModal,
        ValidationProvider,
        ValidationObserver
    },
    metaInfo() {
        return {
            title: this.$t("my_uploaded_documents"),
        };
    },
    data() {
        return {
            form: null,
            terminalOptions: [],
            datatable: {
                isLoading: false,
                columns: [
                    {
                        label: "",
                        field: "buttons",
                        html: true,
                        sortable: false,
                        tdClass:
                            "p-0 text-center w-40 align-middle",
                        buttons: [
                            {
                                text: this.$t("download"),
                                class: "ri-file-download-line",
                                permission: "uploadedstudentdocument_download",
                                callback: (row) => {
                                    this.download(row);
                                },
                            },
                            {
                                text: this.$t("delete"),
                                class: "ri-delete-bin-2-line",
                                permission: "uploadedstudentdocument_delete",
                                callback: (row) => {
                                    this.deleteReceipt(row.id);
                                },
                            }
                        ],
                    },
                    {
                        label: this.toUpperCase("academic_year"),
                        field: 'academic_year',
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("file_name"),
                        field: 'file.file_name',
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("type"),
                        field: this.getLocaleField('type_name'),
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("status"),
                        field: this.getLocaleField('status_name'),
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("explanation"),
                        field: 'explanation',
                        sortable: false,
                    },
                    {
                        label: this.toUpperCase("created_at"),
                        field: 'created_at',
                        formatFn: this.toLocaleDateTime,
                        thClass: 'width-150',
                        sortable: true,
                    },
                ],
                rows: [],
                total: 0,
                filterStatus: true,
                showTable: false,
                queryParams: {
                    filter: {},
                    sort: "id",
                    page: 1,
                    limit: 20,
                },
            },
        };
    },
    methods: {
        createFormShow() {
            this.formId = null
            this.form = {};
            this.$bvModal.show('modal');
        },
        callService(config) {
            return UploadedStudentDocument.student(config)
        },
        getRows() {
            this.$refs.datatable.getRows();
        },
        async save() {
            const isValid = await this.$refs.form.validate();
            if (isValid) {
                let formData = new FormData()
                formData.append('file', this.form.file[0])
                formData.append('academic_year', this.form.academic_year)
                formData.append('type', this.form.type)
                if (this.form.id > 0) {
                    UploadedStudentDocument.update(this.form.id, formData)
                        .then(response => {
                            this.toast(response.data);
                            this.$bvModal.hide('modal');
                            this.getRows();
                        })
                        .catch(e => {
                            this.showErrors(e, this.$refs.form);
                        });
                } else {
                    UploadedStudentDocument.store(formData)
                        .then(response => {
                            this.toast(response.data);
                            this.$bvModal.hide('modal');
                            this.getRows();
                        })
                        .catch(e => {
                            this.showErrors(e, this.$refs.form);
                        });
                }
            }
        },
        showReceipt(row = {}) {
            this.$bvModal.show('modal');
            this.form = row;
        },
        deleteReceipt(id) {
            this.$swal.fire({
                text: this.$t('are_you_sure_to_delete'),
                showCancelButton: true,
                confirmButtonText: this.$t('yes'),
                cancelButtonText: this.$t('no')
            })
                .then((result) => {
                    if (result.isConfirmed) {
                        UploadedStudentDocument.del(id)
                            .then(response => {
                                this.toast(response.data);
                                this.getRows();
                            })
                            .catch(e => {
                                this.showErrors(e);
                            })
                    }
                })
        },
        download(row){
            UploadedStudentDocument.download(row.id).then(response=>{
                this._downloadFile(response, row.file.file_name)
            })
        },
    },
    mounted() {
        this.getRows();
    }
};
</script>

